import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export const router = new Router({
  base: process.env.VUE_APP_BASE_URL,
  mode: 'history',
  routes: [
    {
      path: '',
      alias: '/',
      name: 'home',
      component: () => import('./pages/SignerPage.vue'),
    },
  ],
})
