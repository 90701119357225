import Vue from 'vue'
import Snotify, { SnotifyPosition } from 'vue-snotify'
import 'vue-snotify/styles/material.css'
// import 'vue-snotify/styles/simple.css'

const options = {
  toast: {
    position: SnotifyPosition.rightTop,
    titleMaxLength: 100,
    bodyMaxLength: 100,
  },
}

Vue.use(Snotify, options)
const s = new Vue()

/**
 * How to use ?
 *
 * Ex: this.$notify.success({}) // Default params
 * Ex: this.$notify.success({title: "hi", message: "hi again"})
 */
export const success = function ({ title, message }) {
  message = message || 'successo'

  s.$snotify.success(message, title, {
    timeout: 2000,
    showProgressBar: false,
    maxOnScreen: 1,
  })
}

/**
 * How to use ?
 *
 * Ex: this.$notify.error({title: "hi", message: "hi again"})
 *
 * Ex: this.$notify.error({title: "hi", message: "hi again"})
 */
export const error = function ({ title, message }) {
  title = title || 'erro'
  s.$snotify.error(message, title, {
    timeout: 5000,
    showProgressBar: true,
    closeOnClick: false,
    pauseOnHover: true,
    icon: false,
  })
}

Vue.prototype.$notify = {
  success,
  error,
}
