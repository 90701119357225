import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import * as Snotify from './plugins/Snotify'
import { router } from './router'
import Vuelidate from 'vuelidate'

Vue.config.productionTip = false
Vue.use(Vuelidate)

new Vue({
  vuetify,
  Snotify,
  router,
  render: (h) => h(App),
}).$mount('#app')
